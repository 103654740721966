import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import {
  addDoc,
  collection,
  collectionData,
  CollectionReference,
  doc,
  Firestore,
  orderBy,
  query,
  setDoc,
  Timestamp,
  updateDoc
} from '@angular/fire/firestore';
import { IChatDriver, IChatItem, IMessage } from '@shared/models';
import { AuthService } from '@modules/auth/services/auth.service';
import { BehaviorSubject, catchError, mergeMap, Observable, of, Subscription } from 'rxjs';
import { environment as env } from '@env/environment';
import { filter, map, tap } from 'rxjs/operators';
import { UtilsService } from '@core/services';
import { Howl } from 'howler';

@Injectable({
  providedIn: 'root'
})
export class ChatService {
  private _lastUnreadMsg = 0;
  private _chatInitialized = false;
  private readonly _chatsCollection =
    collection(this.firestore, env.firestore_chat) as unknown as CollectionReference<IChatItem>;
  private _selectedDriver$ = new BehaviorSubject<IChatDriver | null>(null);
  // important to hold subscription from previous opened chat and unsubscribe from it
  private _subscription: Subscription | undefined;

  constructor(private firestore: Firestore, private http: HttpClient, private auth: AuthService,
              private utils: UtilsService) {
  }

  private _chats$ = new BehaviorSubject<IChatItem[] | null>(null);
  get chats$() {
    return this._chats$.asObservable();
  }

  private _chatMessages$ = new BehaviorSubject<IMessage[]>([]);
  get chatMessages$() {
    return this._chatMessages$.asObservable();
  }

  private _unreadChat$ = new BehaviorSubject(false);
  get unreadChat$() {
    return this._unreadChat$.asObservable();
  }

  get selectedDriver(): Observable<IChatDriver | null> {
    return this._selectedDriver$.asObservable();
  }

  set selectedDriver(driver: IChatDriver | null) {
    this._selectedDriver$.next(driver);
    if (!driver) {
      this._subscription?.unsubscribe();
    } else {
      this.getDriverChat(driver.id);
    }
  }

  initChatStream() {
    return this.auth.userData.pipe(
      filter(user => {
        if (!user) return false;
        if (user.type === 'ADMIN') return true;
        const _permissions = user.user_permissions ?? [];
        return _permissions.findIndex(_p => _p.permission.permission_on === 'admin/drivers') > -1;
      }),
      filter(() => !this.utils.isMobile()),
      mergeMap(() => this.getChatList())
    );
  }

  /**
   * Get chat messages for a specific driver from firestore
   */
  getDriverChat(driverId: number) {
    this._subscription?.unsubscribe();
    const _chatCollection =
      collection(this.firestore, env.firestore_chat, driverId.toString(), 'chatroom') as unknown as CollectionReference<IMessage>;
    this._subscription = collectionData(query(_chatCollection, orderBy('date')), { idField: 'message_id' })
      .subscribe(chat => {
        this._chatMessages$.next(chat);
        if (chat[chat.length - 1].sender_id === driverId) {
          setTimeout(() => this.markChatAsRead(driverId));
        }
      });
  }

  /**
   * Update last message sent on the main driver chat
   * @param driverId
   * @param msg
   */
  updateDriverChat(driverId: number, msg: string) {
    return setDoc(doc(this.firestore, env.firestore_chat, driverId.toString()), {
      message: msg,
      sender_id: this.auth.getUserData()?.id,
      sender_name: this.auth.getUserData()?.first_name,
      date: Timestamp.now(),
      read: Timestamp.now()
    });
  }

  markChatAsRead(driverId: number) {
    const _chat = this._chats$.getValue()?.find(_c => _c.driverId === driverId.toString());
    if (_chat && _chat.read && _chat.read.toMillis() > _chat.date.toMillis()) {
      return Promise.resolve();
    }
    return updateDoc(doc(this.firestore, env.firestore_chat, driverId.toString()), {
      read: Timestamp.now()
    });
  }

  /**
   * Create a new message in the driver chatroom
   * @param driverId
   * @param msg
   */
  createChatMessage(driverId: number, msg: string) {
    return addDoc(collection(this.firestore, env.firestore_chat, driverId.toString(), 'chatroom'), {
      message: msg,
      sender_id: this.auth.getUserData()?.id,
      sender_name: this.auth.getUserData()?.first_name,
      date: Timestamp.now()
    });
  }

  /**
   * Get minified driver data to view on each chat item
   * @param driverId
   * @return IChatDriver
   */
  getDriver(driverId: string) {
    return this.http.get(`admin/drivers/${driverId}?dump=1`).pipe(
      catchError(err => {
        return of(null);
      }),
      map((res: any) => {
        if (res && res.status_code === 200) {
          return res.data;
        } else {
          return null;
        }
      })
    );
  }

  /**
   * Search for drivers in the system database with keyword
   * @param search
   */
  searchDrivers(search: string) {
    return this.http.get<any>(`admin/drivers?search=${search}&status=ACTIVE&dump=1&page_size=50`)
      .pipe(map(response => {
        if (response.status_code !== 200) return [];
        const _newChats: any[] = [];
        const _existentChats: any[] = [];
        const _chats = this._chats$.getValue() ?? [];
        response.data.forEach((driver: IChatDriver) => {
          const _chatIndex = _chats?.findIndex((_c) => +_c.driverId === driver.id);
          if (_chatIndex !== -1) {
            _existentChats.push({ ..._chats[_chatIndex], driver: driver });
          } else {
            _newChats.push({ driver: driver, driverId: driver.id.toString() });
          }
        });
        return _existentChats.concat(_newChats);
      }));
  }

  /**
   * Get the list of chats on firestore and appending driverId to the list
   */
  getChatList() {
    return collectionData(query(this._chatsCollection, orderBy('date', 'desc')), { idField: 'driverId' })
      .pipe(tap(data => {
        this._chats$.next(data);
        const _unreadChatIndex = this.hasUnreadChats(data);
        this._unreadChat$.next(_unreadChatIndex > -1);
        if (this._chatInitialized && _unreadChatIndex > -1) {
          if (data[_unreadChatIndex].date.toMillis() > this._lastUnreadMsg) {
            this.playNotificationSound();
            this._lastUnreadMsg = data[_unreadChatIndex].date.toMillis();
          }
        }
        this._chatInitialized ||= true;
      }));
  }

  private hasUnreadChats(chats: IChatItem[]) {
    return chats.findIndex(chat => !chat.read || chat.date.toMillis() > chat.read.toMillis());
  }

  private playNotificationSound() {
    const sound = new Howl({
      src: ['assets/notification.wav'],
    });
    sound.play();
  }
}
